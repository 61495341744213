import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function SectionTwo() {

    const services = [
        {
            title: "Web Development",
            description: "Transform your ideas into powerful web solutions.",
            technologies: [
                {
                    icon: "/images/web-development/web.png", // PHP icon URL
                    title: "PHP",
                    description: "A popular general-purpose scripting language that is especially suited to web development."
                },
                {
                    icon: "/images/web-development/js-file.png", // JavaScript icon URL
                    title: "JavaScript",
                    description: "A high-level, dynamic programming language used for interactive web applications."
                },
                {
                    icon: "/images/web-development/nodejs.png", // Node.js icon URL
                    title: "Node.js",
                    description: "A JavaScript runtime built on Chrome's V8 engine for building scalable network applications."
                },
                {
                    icon: "/images/web-development/outlet.png", // WebSocket icon URL
                    title: "WebSocket",
                    description: "A protocol for full-duplex communication channels over a single TCP connection."
                },
                {
                    icon: "/images/web-development/smart-plug.png", // Socket.io icon URL
                    title: "Socket.io",
                    description: "A library for real-time web applications, enabling bi-directional communication between web clients and servers."
                },
                {
                    icon: "/images/web-development/vuejs.png",// Vue.js icon URL
                    title: "Vue.js",
                    description: "A progressive JavaScript framework for building user interfaces."
                },
                {
                    icon: "/images/web-development/programmer.png", // Nuxt.js icon URL
                    title: "Nuxt.js",
                    description: "A framework based on Vue.js that helps create server-rendered applications."
                },
                {
                    icon: "/images/web-development/mini.png", // MySQL icon URL
                    title: "MySQL",
                    description: "An open-source relational database management system that uses SQL."
                },
                {
                    icon: "/images/web-development/web-development.png", // Laravel icon URL
                    title: "Laravel",
                    description: "A PHP framework for web artisans, providing elegant syntax."
                },
                {
                    icon: "/images/web-development/Language-Translator.png", // Golang icon URL
                    title: "Golang",
                    description: "An open-source programming language designed for simplicity and efficiency."
                },
                {
                    icon: "/images/web-development/django.png", // Django icon URL
                    title: "Django",
                    description: "A high-level Python web framework that encourages rapid development."
                },
                {
                    icon: "/images/web-development/python.png", // Python icon URL
                    title: "Python",
                    description: "A powerful programming language known for its simplicity and versatility."
                }
            ],
            imageUrl: "https://cdni.iconscout.com/illustration/premium/thumb/male-web-developer-doing-research-on-development-illustration-download-in-svg-png-gif-file-formats--thinking-researching-and-pack-design-illustrations-4759504.png",
            link: "service-single.html"
        },
        {
            title: "App Development",
            description: "Cross-platform expertise to bring your app to life.",
            technologies: [
                {
                    icon: "/images/app-development/swift.png", // Swift icon URL
                    title: "Swift",
                    description: "A powerful and intuitive programming language for iOS applications."
                },
                {
                    icon: "/images/app-development/kotlin.png", // Kotlin icon URL
                    title: "Kotlin",
                    description: "A modern programming language used for Android development."
                },
                {
                    icon: "/images/app-development/firebase.png", // Firebase icon URL
                    title: "Firebase",
                    description: "A platform for building mobile and web applications with real-time database capabilities."
                },
                {
                    icon: "/images/app-development/room.png",  // Room icon URL
                    title: "Jetpack Compose",
                    description: "A reactive programming library for Android."
                },
                {
                    icon: "/images/app-development/file.png", // RxSwift icon URL
                    title: "SwiftUI",
                    description: "A modern way to declare user interfaces for any Apple platform."
                },
                {
                    icon: "/images/app-development/trust.png", // Unit Testing icon URL
                    title: "Unit Testing",
                    description: "A software testing method by which individual units of source code are tested."
                }
            ],
            imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiKXH5bw7gOMu1Vyb_M_bS6WoKlVQFVGlGGYIjXqDpwDpM7NlsOFH0DbrgoOf9Tb-gUmw&usqp=CAU",
            link: "service-single.html"
        },
        {
            title: "iOS Development",
            description: "High-performance apps crafted for iOS users.",
            technologies: [
                {
                    icon: "/images/iOS-development/swift.png",// Swift icon URL
                    title: "Swift",
                    description: "A powerful and intuitive programming language for iOS applications."
                },
                {
                    icon: "/images/iOS-development/key-team.png", // SwiftUI icon URL
                    title: "SwiftUI",
                    description: "A modern way to declare user interfaces for any Apple platform."
                },
                {
                    icon: "/images/iOS-development/objective.png",// Objective-C icon URL
                    title: "Objective-C",
                    description: "A general-purpose programming language used for iOS applications."
                },
                {
                    icon: "/images/iOS-development/xcode.png", // Xcode icon URL
                    title: "Xcode",
                    description: "An integrated development environment for macOS containing a suite of software development tools."
                },
                {
                    icon: "/images/iOS-development/uikit.png", // UIKit icon URL
                    title: "UIKit",
                    description: "A framework that provides the required infrastructure for your iOS or tvOS apps."
                },
                {
                    icon: "/images/iOS-development/layers.png", // Core Data icon URL
                    title: "Core Data",
                    description: "A framework that allows data to be persisted on iOS applications."
                },
                {
                    icon: "/images/iOS-development/api.png", // RESTful APIs icon URL
                    title: "RESTful APIs",
                    description: "Representational state transfer is an architectural style for designing networked applications."
                },
                {
                    icon: "/images/iOS-development/firebase.png",  // Firebase icon URL
                    title: "Firebase",
                    description: "A platform for building mobile and web applications."
                },
                {
                    icon: "/images/iOS-development/testflight.png", // TestFlight icon URL
                    title: "TestFlight",
                    description: "An online service for over-the-air installation and testing of mobile applications."
                },
                {
                    icon: "/images/iOS-development/weather.png",// App Store Deployment icon URL
                    title: "App Store Deployment",
                    description: "The process of submitting apps to the Apple App Store."
                }
            ],
            imageUrl: "https://cdni.iconscout.com/illustration/premium/thumb/app-development-illustration-download-in-svg-png-gif-file-formats--html-logo-design-programming-software-user-interface-business-related-services-pack-illustrations-1534572.png",
            link: "service-single.html"
        },
        {
            title: "Android Development",
            description: "Build intuitive and scalable Android applications.",
            technologies: [
                {
                    icon: "/images/android-development/java.png", // Java icon URL
                    title: "Java",
                    description: "A high-level programming language used for Android app development."
                },
                {
                    icon: "/images/android-development/kotlin.png",
                    title: "Kotlin",
                    description: "A modern programming language used for Android development."
                },
                {
                    icon: "/images/android-development/android.png",
                    title: "Android Studio",
                    description: "The official integrated development environment for Google's Android operating system."
                },
                {
                    icon: "/images/android-development/api.png",
                    title: "RESTful APIs",
                    description: "Architectural style for designing networked applications."
                },
                {
                    icon: "/images/android-development/firebase.png",
                    title: "Firebase",
                    description: "A platform for building mobile and web applications."
                },
                {
                    icon: "/images/android-development/server-control.png",
                    title: "Room Database",
                    description: "A persistence library providing an abstraction layer over SQLite."
                },
                {
                    icon: "/images/android-development/developer.png",
                    title: "Jetpack",
                    description: "A suite of libraries, tools, and architectural guidance for Android development."
                },
                {
                    icon: "/images/android-development/social.png",
                    title: "Gradle",
                    description: "A build automation tool that is used for Java projects."
                }
            ],
            imageUrl: "https://img.lovepik.com/element/45015/7401.png_860.png",
            link: "service-single.html"
        },
        {
            title: "UI Design",
            description: "Design experiences that captivate and engage.",
            technologies: [
                {
                    icon: "/images/ui-design/wireframe.png",
                    title: "Wireframing",
                    description: "The process of creating a visual representation of a user interface."
                },
                {
                    icon: "/images/ui-design/prototype.png",
                    title: "Prototyping",
                    description: "An early model of a product used to test concepts and ideas."
                },
                {
                    icon: "/images/ui-design/search.png",
                    title: "User Research",
                    description: "A variety of techniques used to understand user needs and behaviors."
                },
                {
                    icon: "/images/ui-design/figma.png",
                    title: "Figma",
                    description: "A web-based UI design application for collaborative interface design."
                },
                {
                    icon: "/images/ui-design/xd.png",
                    title: "Adobe XD",
                    description: "A vector-based user experience design tool for web apps and mobile apps."
                },
                {
                    icon: "/images/ui-design/sketch.png",
                    title: "Sketch",
                    description: "A digital design toolkit for creating user interfaces and prototypes."
                },
                {
                    icon: "/images/ui-design/interaction.png",
                    title: "Interaction Design",
                    description: "Designing interactive elements in user interfaces."
                },
                {
                    icon: "/images/ui-design/responsive-design.png",
                    title: "Responsive Design",
                    description: "An approach to web design that makes web pages render well on various devices."
                }
            ],
            imageUrl: "https://ouch-cdn2.icons8.com/_CSoBNAVwGntrVSN2-B4a3rfb47gC6c60xn7uVs8QrA/rs:fit:633:456/extend:false/wm:1:re:0:0:0.8/wmid:ouch/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvMjUv/MjFmNzBhOTgtMDU0/Ny00OThmLTkyMTYt/NjA0ZjQyNDRmMWIx/LnN2Zw.png",
            link: "service-single.html"
        },
        {
            title: "Cloud Solutions",
            description: "Optimize for scale, security, and efficiency.",
            technologies: [
                {
                    icon: "/images/cloud-solution/docker.png",
                    title: "Docker",
                    description: "A platform for developing, shipping, and running applications inside containers."
                },
                {
                    icon: "/images/cloud-solution/Kubernetes.png",
                    title: "Kubernetes",
                    description: "An open-source system for automating deployment, scaling, and management of containerized applications."
                },
                {
                    icon: "/images/cloud-solution/develop.png",
                    title: "Jenkins",
                    description: "An open-source automation server that enables developers to build, test, and deploy applications."
                },
                {
                    icon: "/images/cloud-solution/social.png",
                    title: "AWS",
                    description: "Amazon Web Services, a comprehensive cloud platform provided by Amazon."
                },
                {
                    icon: "/images/cloud-solution/azure.png",
                    title: "Azure",
                    description: "A cloud computing service created by Microsoft for building, testing, deploying, and managing applications."
                },
                {
                    icon: "/images/cloud-solution/google.png",
                    title: "Google Cloud",
                    description: "A suite of cloud computing services offered by Google."
                },
                {
                    icon: "/images/cloud-solution/terraform.png",
                    title: "Terraform",
                    description: "An open-source infrastructure as code software tool that provides a consistent CLI workflow."
                },
                {
                    icon: "/images/cloud-solution/ansible.png",
                    title: "Ansible",
                    description: "An open-source automation tool that automates software provisioning, configuration management, and application deployment."
                }
            ],
            imageUrl: "https://w1.pngwing.com/pngs/99/547/png-transparent-cloud-symbol-devops-business-process-software-development-process-software-deployment-information-technology-implementation-cloud-computing.png",
            link: "service-single.html"
        },
    ];


    const navigate = useNavigate();

    const slugifyTitle = (title) => title.toLowerCase().replace(/ /g, '-');

    const handleServiceClick = (service) => {
        navigate(`/service/${slugifyTitle(service.title)}`, { state: { service } });

    };

    return (
        <>
            <section className="lg:py-24 py-10" >
                <div className="wraper">
                    <div className="text-center mb-5 lg:mb-[80px]">
                        <h2 className="font-roboto font-bold text-24 lg:text-50 leading-36 lg:leading-60 capitalize
                    mt-[15px] relative text-[#232f4b] ">Comprehensive <span className="text-blue-600">Development</span>   <br className='lg:block hidden' /> Services</h2>
                        <p className='font-roboto font-medium'>Harness the Power of Cutting-Edge Technologies</p>
                    </div >
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-4">
                        {services?.map((service, index) => (
                            <div className="text-center mb-4" key={index} onClick={() => handleServiceClick(service)}>
                                <div className='relative mx-auto max-w-md rounded-lg bg-gradient-to-tr from-pink-300 to-blue-300 p-0.5 shadow-lg'>
                                    <div className="bg-white p-[10px] hover:bg-gradient-to-tr from-pink-300 to-blue-300 lg:p-[30px] rounded-lg">
                                        <div className="w-[70px] h-[70px] bg-[#f3f6fe] leading-[70px] text-center rounded-[50px] overflow-hidden flex items-center justify-center mx-auto">
                                            <img className="md:w-[50px] w-full leading-[50px] h-auto md:h-[50px]" src={service.imageUrl} alt={service.title} />
                                        </div>

                                        <h2 className="text-24 font-roboto font-bold mt-[20px]">
                                            <a href={service.link} className="text-[#232f4b] hover:text-[#3757f7] transition-all">
                                                {service.title}
                                            </a>
                                        </h2>
                                        <p className='text-13 font-roboto font-normal pb-4 text-[#232f4b]'>{service.description}</p>
                                        <p className=' font-roboto font-bold h-20 overflow-auto'>
                                            <b className='text-[#232f4b] font-17'>Technologies:</b> 
                                            <br/>
                                         <span className='text-[#232f4b] font-13'>   {service.technologies.slice(0, 5).map((technology) => technology.title).join(', ')}</span>
                                            <span className='text-blue-600 cursor-pointer'>  ...more</span>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div >
            </section >
        </>
    )
}
