import React from 'react';
import FullTimeCourse from '../../src/outside/CourseFullTime.pdf';
import SelfPaceCoursePdf from '../../src/outside/iOSSelfPaceSyllabus.pdf';
import ios from '../../src/outside/ios.png';
import { Link } from "react-router-dom";

const IOS = () => {

  const viewSyllabus = () => {
    window.open(`${FullTimeCourse}`, '_blank');
  }

  const startSubscription = () => {
    window.open("https://buy.stripe.com/cN22bLf219VydSEbII", 'blank');
  }

  const SelfPaceCourse = () => {
    window.open(`${SelfPaceCoursePdf}`, '_blank');
  }

  return (
    <>

      <section className='bg-sky-100'>
        <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col items-center justify-between w-full mb-3 lg:flex-row">
            <div className="mb-6 lg:mb-0 lg:max-w-lg lg:pr-5">
              <div className="max-w-xl mb-6">
                <div>
                </div>
                <h2 className="max-w-4xl font-roboto  mt-4 font-bold text-slate-900 text-24 lg:text-50 leading-36 lg:leading-60">
                  Transforming Your Ideas Into <span className='text-[#1e62ec]'>Reality </span>
                  <span className="inline-block text-deep-purple-accent-400"> just got real</span>
                </h2>
                <p className="text-base font-roboto font-normal text-gray-700 md:text-lg mt-3" >
                  Everything you can envision, you can create. Unlock your potential and bring your app ideas to life with expert guidance and hands - on experience.
                </p >
              </div >
            </div >
            <div className="flex items-center justify-center" >
              <img src={ios} alt='' className="transition-transform duration-300 ease-in-out transform hover:scale-105 "
              />
            </div >
          </div >
        </div >
      </section >
      <section className=''>
        <div className="bg-indigo-100 lg:py-24 py-10">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4  py-18 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div>
              <h2 className="font-bold font-roboto text-24 lg:text-50 leading-36 lg:leading-60 text-gray-900">   <span className='text-[#1e62ec]'> iOS </span> Full-Time Program</h2>
              <div className='flex flex-wrap'>
                <h2 className="mt-4 text-[#1e62ec] text-1xl font-bold flex-initial font-roboto w-28">$19,950</h2>
                <h2 className="mt-4 text-gray-900 flex-initial place-content-center font-roboto">Currently Online</h2>
              </div>
              <h2 className="mt-8 font-bold font-roboto  text-gray-900 text-24 lg:text-50 leading-36 lg:leading-60">What’s  <span className='text-[#1e62ec]'> Included? </span></h2>
              <dl className="mt-">
                <ul className="list-disc pl-4">
                  <li className="font-normal text-gray-900 mt-3 font-roboto">
                    Full time 5 month App Development course. Learn to build professional Apps and launch them on the App Store.
                  </li>
                  <li className="font-normal text-gray-900 mt-3 font-roboto">
                    4 months of career launch support with: mock interviews with experienced engineers, resume prep, and MORE client project experience.
                  </li>
                  <li className="font-normal text-gray-900 mt-3 font-roboto">
                    Daily instruction is with our experienced Engineers who bring unmatched expertise and insights in the industry.
                  </li>
                  <li className="font-normal text-gray-900 mt-3 font-roboto">
                    Included is an unpaid internship program where you will have multiple opportunities to work on client projects and gain real world experience.
                  </li>
                </ul>
                <div className=" block md:flex">
                  <button onClick={viewSyllabus} className="relative w-full md:w-48 block md:inline-flex  mt-6 items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                    <span className="relative w-full block md:inline px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                      View Syllabus
                    </span>
                  </button>
                  <Link className=""
                    to="/contact">
                    <button className="relative w-full md:w-48 block md:inline-flex mt-6 items-center justify-center p-0.5 lg:mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                      <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                      Learn More
                      </span>
                    </button>
                  </Link>
                  <Link className=""
                    to="/financing">
                    <button className="relative w-full md:w-48 block md:inline-flex mt-6 items-center justify-center p-0.5 lg:mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                      <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                      Financing
                      </span>
                    </button>
                  </Link>
                </div>
              </dl>
            </div>
            <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-7 xl:gap-8">
              {/* Image 1: Scale on hover */}
              <figure className="overflow-hidden rounded-lg lg:w-60 xl:w-72 w-full lg:h-60 h-40 bg-white">
                <img
                  src="/images/blog/2.jpg"
                  alt="example"
                  className="rounded-lg h-40 lg:h-64 w-full object-cover bg-gray-100 transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </figure>

              <figure className="overflow-hidden rounded-lg lg:w-60 xl:w-72 w-full lg:h-60 h-40 bg-white">
                <img
                  src="/images/blog/woman-mobile.png"
                  alt="example"
                  className="rounded-lg h-40 lg:h-64 w-full object-cover bg-gray-100 transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </figure>


              {/* Image 3: Brightness increase on hover */}
              <figure className="overflow-hidden rounded-lg lg:w-60 xl:w-72 w-full lg:h-60 h-40 bg-white">
                <img
                  src="/images/blog/online.png"
                  alt="example"
                  className="rounded-lg h-40 lg:h-64 w-full object-cover bg-gray-100 transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </figure>



              {/* Image 4: Blur on hover */}
              <figure className="overflow-hidden rounded-lg lg:w-60 xl:w-72 w-full lg:h-60 h-40 bg-white">
                <img
                  src="/images/blog/course.png"
                  alt="example"
                  className="rounded-lg h-40 lg:h-64 w-full object-cover bg-gray-100 transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </figure>

            </div>
          </div >
        </div >

        <div className="bg-purple-50  lg:py-24 py-10">
          <div className="">
            <div className="mx-auto flex flex-col-reverse lg:grid max-w-2xl grid-cols-1 lg:items-center gap-x-8 gap-y-16 px-4 py-4 sm:px-6 sm:py-12 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
              <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-7 xl:gap-8">
                <figure className="overflow-hidden rounded-lg lg:w-60 xl:w-72 w-full lg:h-60 h-40 bg-white">
                  <img
                    src="/images/blog/game.png"
                    alt="example"
                    className="rounded-lg h-40 lg:h-64 w-full object-cover bg-gray-100 transition-transform duration-300 ease-in-out hover:scale-110"
                  />
                </figure>

                <figure className="overflow-hidden rounded-lg lg:w-60 xl:w-72 w-full lg:h-60 h-40 bg-white">
                  <img
                    src="/images/blog/girl.png"
                    alt="example"
                    className="rounded-lg h-40 lg:h-64 w-full object-cover bg-gray-100 transition-transform duration-300 ease-in-out hover:scale-110"
                  />
                </figure>


                {/* Image 3: Brightness increase on hover */}
                <figure className="overflow-hidden rounded-lg lg:w-60 xl:w-72 w-full lg:h-60 h-40 bg-white">
                  <img
                    src="/images/blog/social.png"
                    alt="example"
                    className="rounded-lg h-40 lg:h-64 w-full object-cover bg-gray-100 transition-transform duration-300 ease-in-out hover:scale-110"
                  />
                </figure>



                {/* Image 4: Blur on hover */}
                <figure className="overflow-hidden rounded-lg lg:w-60 xl:w-72 w-full lg:h-60 h-40 bg-white">
                  <img
                    src="/images/blog/4.jpg"
                    alt="example"
                    className="rounded-lg h-40 lg:h-64 w-full object-cover bg-gray-100 transition-transform duration-300 ease-in-out hover:scale-110"
                  />
                </figure>
              </div>
              <div className="pl-2 lg:pl-5">
                <h2 className="font-bold text-24 lg:text-50 leading-36 lg:leading-60 text-gray-900  font-roboto"> <span className='text-[#1e62ec]'> FREE iOS </span> Starter Course</h2>
                <div className="flex flex-wrap">
                  <h2 className="mt-4 text-[#1e62ec] text-1xl font-bold flex-initial w-40 font-roboto">Was $2000</h2>
                </div>
                <h2 className="lg:mt-8 mt-4  font-bold text-24 lg:text-50 leading-36 lg:leading-60 text-gray-900 font-roboto">What’s <span className='text-[#1e62ec]'> Included? </span>  </h2>
                <dl className="mt- pl-2 lg:pl-5">
                  <ul className="list-disc">
                    <li className="font-normal text-gray-900 mt-3 font-roboto">
                      Code-along and learn with over 50 hrs of recorded content.
                    </li>
                    <li className="font-normal text-gray-900 mt-3 font-roboto">
                      Access to our Slack community and get help and guidance from an experienced iOS engineer.
                    </li>
                    <li className="font-normal text-gray-900 mt-3 font-roboto">
                      Build apps and get a taste of App development.
                    </li>
                  </ul>
                  <div className=" block md:flex">
                    {/* <button onClick={SelfPaceCourse} className="relative w-full md:w-48 block md:inline-flex  mt-6 items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                      <span className="relative w-full block md:inline px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                        View Syllabus
                      </span>
                    </button> */}
                    {/* <button onClick={startSubscription} className="relative md:ml-4 w-full md:w-48 block md:inline-flex mt-6 items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                      <span className="relative  w-full block md:inline px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                        Start Subscription
                      </span>
                    </button> */}
                    <Link className=""
                    to="/contact">
                    <button className="relative w-full md:w-80 block md:inline-flex mt-6 items-center justify-center p-0.5 lg:mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                      <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                        Learn More →
                      </span>
                    </button>
                  </Link>
                  </div>
                </dl>
              </div >
            </div >
          </div >
        </div >
        <div className="bg-slate-50 lg:py-24 py-4">
          <div className="mx-auto grid max-w-2xl grid-cols-1  gap-x-8 gap-y-16 px-4 py-6 sm:px-6 sm:py-12 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div className='pl-2 lg:pl-5'>
              <h2 className=" font-bold text-24 lg:text-50 leading-36 lg:leading-60 text-gray-900  font-roboto">Additional  <span className='text-[#1e62ec]'> Services </span> </h2>
              <dl className="mt-8">
                <ul className="list-disc">
                  <li className="font-normal text-gray-900 mt-3 font-roboto">
                    <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">Resume Preparation:</span> We will help you optimize your resume.
                  </li>
                  <li className="font-normal text-gray-900 mt-3 font-roboto">
                    <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">Salary Negotiation:</span> We coach you to get up to $50,000 increase in salary.
                  </li>
                  <li className="font-normal text-gray-900 mt-3 font-roboto">
                    <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">Mock Interviews:</span> Expert Guidance and Support with a Senior Engineer.
                  </li>
                  <li className="font-normal text-gray-900 mt-3 font-roboto">
                    <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">Work on Client Projects:</span> Get more experience working on real client projects.
                  </li>
                  <li className="font-normal text-gray-900 mt-3 font-roboto">
                    <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">Personal Project Assistance:</span> We will assist you in launching your apps on the App Store.
                  </li>
                </ul>
              </dl>
            </div>
            <div className='pl-2 lg:pl-5'>
              <div>
                <h2 className=" font-bold text-gray-900 text-24 lg:text-50 leading-36 lg:leading-60 font-roboto">What <span className='text-[#1e62ec]'> Skills Will   </span> You Learn?</h2>
                <dl className="mt-8">
                  <ul className="list-disc">
                    <li className="font-normal text-gray-900 mt-3 font-roboto">
                      <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">MVVM</span> and <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">SwiftUI: </span> Apple’s preferred UI framework for all devices.
                    </li>
                    <li className="font-normal text-gray-900 mt-3 font-roboto">
                      <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">Persistence:</span> Working with FileManager, SwiftData, CoreData, and closures.
                    </li>
                    <li className="font-normal text-gray-900 mt-3 font-roboto">
                      <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">Networking:</span>  Managing APIs with URLSession, JSONSerialization, Codable, and Concurrency.
                    </li>
                    <li className="font-normal text-gray-900 mt-3 font-roboto">
                      <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">Advanced Networking:</span> Work with image data, Async Await, and Combine.
                    </li>
                    <li className="font-normal text-gray-900 mt-3 font-roboto">
                      <span className="font-bold tracking-tight text-gray-900 sm:text-1xl">Firebase Integration:</span> Store and retrieve data, including image handling.
                    </li>
                  </ul>
                </dl>
              </div>
            </div>
          </div >
        </div >

      </section >

    </>
  );
};

export default IOS;